/* Reviewed By Rabbil Hasan*/

.ct-flex-bottom{
    margin-bottom: 50px !important;
}
.ct-text{
    font-family: 'Baloo Da 2', cursive !important;
    font-size: 20px !important; ;
    font-weight: 400 !important;
    color: #000000;
}