/* Reviewed By Rabbil Hasan */
.product-details-title{
    font-weight: 500;
    font-family: 'Baloo Da 2', cursive !important;
    color: #212121 !important;
    font-size: 28px;
}

.product-details-sub-title{
    font-weight: 400;
    font-family: 'Baloo Da 2', cursive !important;
    color: #494949 !important;
    font-size: 16px;
}


.description{
    font-weight: 400;
    font-family: 'Baloo Da 2', cursive !important;
    color: #494949 !important;
    font-size: 15px;
}

