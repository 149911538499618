/*...Reviewed By Rabbil Hasan...*/
.btn {
    font-family: 'Baloo Da 2', cursive !important;
    cursor: pointer !important;
}
.btn:focus{
    box-shadow: none !important;;
}
.btn-dark{
    font-family: 'Baloo Da 2', cursive !important;
    border-color: #46C062 !important;
    background: #46C062  !important;
}
.btn-dark:hover{
    border-color: #000000 !important;
    background: #000000 !important;
}
.theme-btn{
    font-family: 'Baloo Da 2', cursive !important;
    border-radius: 20px;
    border: 0.5px solid #f1f1f1;
    background-color: #ffffff;
    color: #46C062;
    box-shadow: 0 .08rem .08rem rgba(0,0,0,.075) !important;
}
.theme-btn:hover{
    border-radius: 20px;
    border: 0.5px solid #46C062;
    background-color:#46C062;
    color: #ffffff;
    box-shadow: 0 .15rem .15rem rgba(0,0,0,.075) !important;
}