/*...Reviewed By Rabbil Hasan...*/

@media (max-width: 374.98px) {
    .top-banner{
        height: 100px !important;
    }
    .banner-item-img{
        width: auto;
        height: 150px;
    }
    .banner-title{
        font-size: 28px;
        font-weight: 700;
    }
    .banner-sub-title{
        font-size: 18px;
        font-weight: 500;
    }
    .section-title{
        font-weight: 400;
        font-size: 16px !important;
    }
    .card-title{
        font-size: 14px;
        font-weight: 300;
    }
    .card-price-title{
        font-size: 14px;
        font-weight: 300;
    }
    .review-title{
        font-size: 16px !important;
    }

    .product-details-title{
        font-size: 20px !important;
    }
    .product-details-sub-title{
        font-size: 15px !important;
     }

    .login-form{
        padding: 20px ;
    }

    .cart-list-text{
        font-size: 13px;
        font-weight: 300 !important;
    }

    .mobile{

    }
    .desktop{
        display: none !important;
    }

}


@media (min-width: 375.98px) and (max-width: 575.98px){
    .top-banner{
        height: 100px !important;
    }
    .banner-item-img{
        width: auto;
        height: 160px;
    }
    .banner-title{
        font-size: 32px;
        font-weight: 700;
    }
    .banner-sub-title{
        font-size: 24px;
        font-weight: 500;
    }
    .section-title{
        font-weight: 400;
        font-size: 20px !important;
    }

    .card-title{
        font-size: 15px;
        font-weight: 300;
    }
    .card-price-title{
        font-size: 15px;
        font-weight: 300;
    }

    .review-title{
        font-size: 16px !important;
    }
    .product-details-title{
        font-size: 20px !important;
    }
    .product-details-sub-title{
        font-size: 15px !important;
    }

    .cart-list-text{
        font-size: 14px;
        font-weight: 300 !important;
    }

    .mobile{

    }
    .desktop{
        display: none !important;
    }
}


@media (min-width: 576px) and (max-width: 767.98px) {
    .top-banner{
        height: 260px !important;
    }
    .banner-item-img{
        width: auto;
        height: 160px;
    }
    .banner-title{
        font-size: 36px;
        font-weight: 700;
    }
    .banner-sub-title{
        font-size: 28px;
        font-weight: 500;
    }
    .section-title{
        font-weight: 400;
        font-size: 22px !important;
    }

    .card-title{
        font-size: 16px;
        font-weight: 300;
    }

    .card-price-title{
        font-size: 16px;
        font-weight: 300;
    }
    .review-title{
        font-size: 16px !important;
    }

    .product-details-title{
        font-size: 20px !important;
    }
    .product-details-sub-title{
        font-size: 15px !important;
    }

    .cart-list-text{
        font-size: 15px;
        font-weight: 300 !important;
    }

    .mobile{

    }
    .desktop{
        display: none !important;
    }

}


@media (min-width: 768px) and (max-width: 991.98px) {
    .top-banner{
        min-height: 300px !important;
    }
    .top-banner-margin{
        margin-top: 50px;
    }
    .banner-item-img{
        width: auto;
        height: 220px;
    }
    .banner-title{
        font-size: 32px;
        font-weight: 700;
    }
    .banner-sub-title{
        font-size: 24px;
        font-weight: 500;
    }
    .card-title{
        font-size: 18px;
        font-weight: 500;
    }
    .card-price-title{
        font-size: 18px;
        font-weight: 500;
    }

    .section-title{
        font-weight: 400;
        font-size: 24px !important;
    }

    .review-title{
        font-size: 18px !important;
    }


    .product-details-title{
        font-size: 22px !important;
    }
    .product-details-sub-title{
        font-size: 16px !important;
    }



    .mobile{
        display: none !important;
    }
    .desktop{

    }

}


@media (min-width: 992px) and (max-width: 1199.98px) {

    .top-banner{
        min-height: 360px !important;
    }
    .top-banner-margin{
        margin-top: 65px;
    }
    .banner-item-img{
        width: auto;
        height: 280px;
    }
    .banner-title{
        font-size: 42px;
        font-weight: 700;
    }
    .banner-sub-title{
        font-size: 32px;
        font-weight: 500;
    }


    .mobile{
        display: none !important;
    }
    .desktop{

    }
}

@media (min-width: 1200px) {
    .mobile{
        display: none !important;
    }
    .desktop{

    }

}
