/* Reviewed By Rabbil Hasan */
.review-title{
    font-family: 'Baloo Da 2', cursive !important;
    font-size: 20px;
    font-weight: 500;
    color: #1b1e21;
}

.review-des{
    font-family: 'Baloo Da 2', cursive !important;
    font-size: 15px;
    font-weight: 300;
    color: #494949;
}