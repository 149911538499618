.ProcessingDiv{
    position: fixed;
    top: 0 !important;
    bottom: 0 !important;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.8);
    z-index: 2000  !important;
}
.center-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}
