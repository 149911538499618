/*...Reviewed By Rabbil Hasan...*/
.site-footer {
    background-color:#1b1e21;
    padding:45px 0 20px;
    font-size:15px;
    line-height:24px;
    color: #d0d0d0;
    box-shadow: .2rem .2rem .4rem rgba(0,0,0,.075) !important;

}
.site-footer hr {
    border-top-color:#bbb;
    opacity:0.5
}
.site-footer hr.small {
    margin:20px 0
}
.site-footer h6 {
    color: #acacac;
    font-size:16px;
    text-transform:uppercase;
    margin-top:5px;
}
.site-footer a {
    color:#737373;
}
.site-footer a:hover {
    color:#3366cc;
    text-decoration:none;
}

.footer-links {
    padding-left:0;
    list-style:none
}
.footer-links li {
    display:block
}
.footer-links a {
    color: #d0d0d0;
}
.footer-links a:active,.footer-links a:focus,.footer-links a:hover {
    color:#3366cc;
    text-decoration:none;
}
.footer-links.inline li {
    display:inline-block
}
.site-footer .social-icons {
    text-align:right
}
.site-footer .social-icons a {
    width:40px;
    height:40px;
    line-height:40px;
    margin-left:6px;
    margin-right:0;
    border-radius:100%;
    background-color:#33353d
}
.copyright-text {
    margin:0;
    color: #ffffff;
}

@media (max-width:991px)
{
    .site-footer [class^=col-]
    {
        margin-bottom:30px
    }
}
@media (max-width:767px)
{
    .site-footer
    {
        padding-bottom:0
    }
    .site-footer .copyright-text,.site-footer .social-icons
    {
        text-align:center
    }
}
.social-icons
{
    padding-left:0;
    margin-bottom:0;
    list-style:none
}
.social-icons li
{
    display:inline-block;
    margin-bottom:4px
}
.social-icons li.title
{
    margin-right:15px;
    text-transform:uppercase;
    color:#96a2b2;
    font-weight:700;
    font-size:13px
}
.social-icons a{
    background-color:#eceeef;
    color:#818a91;
    font-size:16px;
    display:inline-block;
    line-height:44px;
    width:44px;
    height:44px;
    text-align:center;
    margin-right:8px;
    border-radius:100%;
    -webkit-transition:all .2s linear;
    -o-transition:all .2s linear;
    transition:all .2s linear
}
.social-icons a:active,.social-icons a:focus,.social-icons a:hover
{
    color:#fff;
    background-color:#29aafe
}
.social-icons.size-sm a
{
    line-height:34px;
    height:34px;
    width:34px;
    font-size:14px
}
.social-icons a.facebook:hover
{
    background-color:#3b5998
}
.social-icons a.youtube:hover
{
    background-color: #ff2727
}
.social-icons a.linkedin:hover
{
    background-color:#007bb6
}
.social-icons a.dribbble:hover
{
    background-color:#ea4c89
}
@media (max-width:767px)
{
    .social-icons li.title
    {
        display:block;
        margin-right:0;
        font-weight:600
    }
}

.Download-app{
    height: 100%;
    width: auto;
    max-height: 50px;
    padding-left: 0px!important;
}


.footer-title{
    font-family: 'Baloo Da 2', cursive !important;
    font-weight: 500;
    font-size: 20px!important;
    color: #ffffff;
    text-decoration: none!important;
}

.footer-des{
    font-family: 'Baloo Da 2', cursive !important;
    font-weight: 300;
    font-size: 16px!important;
    color: #d0d0d0;
    text-decoration: none!important;
}

.FooterInfoLinkText{
    font-family: 'Baloo Da 2', cursive !important;
    font-weight: 300;
    font-size: 16px!important;
    color: #f4f4f4 !important;
    text-decoration: none!important;
}

.FooterInfoLinkText:hover{
    font-family: 'Baloo Da 2', cursive !important;
    font-weight: 400;
    font-size: 16px!important;
    color: #46C062 !important;
    text-decoration: none!important;
}

