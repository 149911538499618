.text-success{
    color: #8CC737 !important;
}

.text-danger{
    color: #ED1820 !important;
}

.checkout-item{
    font-size: 18px;
    font-family: 'Baloo Da 2', cursive !important;
    font-weight: 400;
    color: #1b1e21;
    text-decoration: none;
}

.checkout-item-total{
    font-size: 20px;
    font-family: 'Baloo Da 2', cursive !important;
    font-weight: 600;
    color: #1b1e21;
    text-decoration: none;
}


.aboutTitle{
    font-size: 28px;
    font-family: 'Baloo Da 2', cursive !important;
    font-weight: 400;
    color: #1b1e21;
    text-decoration: none;
}

.aboutDescription{
    font-size: 16px;
    font-family: 'Baloo Da 2', cursive !important;
    font-weight: 300;
    color: #1b1e21;
    text-decoration: none;
}