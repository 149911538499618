/* Reviewed By Rabbil Hasan */
.form-control{
    border: 0.5px solid #cfcfcf !important;
    border-radius: 2px !important;
    background-color: #ffffff !important;
    color: #1b1e21 !important;
    font-family: 'Baloo Da 2', cursive !important;
}
.form-control:focus {
    border-color: #929292;
    box-shadow: none !important;
}

.login-form{
    padding: 30px;
}

.form-control-box{
    font-family: 'Baloo Da 2', cursive !important;
    border-radius: 0 !important;
    background-color: #FDFDFF !important;
    font-weight: 500 !important;
    width: 30px !important;
    height: 30px!important;
}
.form-control-box:hover{
    border-radius: 0 !important;
    background-color: #FDFDFF !important;
    font-weight: 500 !important;
    width: 30px !important;
    height: 30px!important;
}

.form-label{
    font-family: 'Baloo Da 2', cursive !important;
    font-size: 12px;
    font-weight: 400;
}