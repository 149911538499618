/*...Reviewed By Rabbil Hasan...*/

.card {
    border: none !important;
    border-radius:4px !important;
    box-shadow: 0 0 8px 0 rgba(57, 78, 234, 0.1);
    transition: 0.3s;
    text-decoration: none !important;
}
.card-body-custom{
    padding: 10px !important;
}
.card:hover {
    box-shadow: 0 0 12px 0 rgba(57, 78, 234, 0.1);
    text-decoration: none !important;
    cursor:pointer;
}
.card-title{
    text-decoration: none!important;
    font-size: 18px;
    font-family: 'Baloo Da 2', cursive !important;
    font-weight: 500;
    color: #1b1e21!important;
    transition: 0.3s;
}
.card-title-lg{
    text-decoration: none!important;
    font-size: 22px;
    font-family: 'Baloo Da 2', cursive !important;
    font-weight: 400;
    color: #1b1e21!important;
    transition: 0.3s;
}
.card-price-title{
    text-decoration: none!important;
    font-size: 18px;
    font-family: 'Baloo Da 2', cursive !important;
    font-weight: 300;
    color: #1b1e21!important;
    transition: 0.3s;
}
.card-order-btn{
    margin-top: 7px;
    border-radius: 0 !important;
    font-weight: 400;
}
.strikediag {
    font-size: 14px !important;
    background: linear-gradient(to left top, transparent 47.75%, currentColor 49.5%, currentColor 50.5%, transparent 52.25%);
}
.withpadding {
    padding: 0 0.15em;
}

.image-box {
    position: relative;
    margin: auto;
    overflow: hidden;
    width: 100% !important;
}
.image-box img {
    width: 100% !important;
    transition: all 0.3s;
    display: block;
    height: auto;
    transform: scale(1);
}
.image-box:hover img {
    transform: scale(1.1);
}


.badge-overlay {
    position: absolute;
    left: 0%;
    top: 0px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    z-index: 100;
    -webkit-transition: width 1s ease, height 1s ease;
    -moz-transition: width 1s ease, height 1s ease;
    -o-transition: width 1s ease, height 1s ease;
    transition: width 0.4s ease, height 0.4s ease
}

/* ================== Badge CSS ========================*/
.badge {
    margin: 0;
    padding: 0;
    color: white;
    padding: 10px 10px;
    font-size: 15px;
    font-family: 'Baloo Da 2', cursive !important;
    text-align: center;
    line-height: normal;
    text-transform: uppercase;
    background: #ed1b24;
}

.badge::before, .badge::after {
    content: '';
    position: absolute;
    top: 0;
    margin: 0 -1px;
    width: 100%;
    height: 100%;
    background: inherit;
    min-width: 55px
}

.badge::before {
    right: 100%
}

.badge::after {
    left: 100%
}

/* ================== Badge Position CSS ========================*/
.top-left {
    position: absolute;
    top: 0;
    left: 0;
    -ms-transform: translateX(-30%) translateY(0%) rotate(-45deg);
    -webkit-transform: translateX(-30%) translateY(0%) rotate(-45deg);
    transform: translateX(-30%) translateY(0%) rotate(-45deg);
    -ms-transform-origin: top right;
    -webkit-transform-origin: top right;
    transform-origin: top right;
}

.top-right {
    position: absolute;
    top: 0;
    right: 0;
    -ms-transform: translateX(30%) translateY(0%) rotate(45deg);
    -webkit-transform: translateX(30%) translateY(0%) rotate(45deg);
    transform: translateX(30%) translateY(0%) rotate(45deg);
    -ms-transform-origin: top left;
    -webkit-transform-origin: top left;
    transform-origin: top left;
}

.bottom-left {
    position: absolute;
    bottom: 0;
    left: 0;
    -ms-transform: translateX(-30%) translateY(0%) rotate(45deg);
    -webkit-transform: translateX(-30%) translateY(0%) rotate(45deg);
    transform: translateX(-30%) translateY(0%) rotate(45deg);
    -ms-transform-origin: bottom right;
    -webkit-transform-origin: bottom right;
    transform-origin: bottom right;
}

.bottom-right {
    position: absolute;
    bottom: 0;
    right: 0;
    -ms-transform: translateX(30%) translateY(0%) rotate(-45deg);
    -webkit-transform: translateX(30%) translateY(0%) rotate(-45deg);
    transform: translateX(30%) translateY(0%) rotate(-45deg);
    -ms-transform-origin: bottom left;
    -webkit-transform-origin: bottom left;
    transform-origin: bottom left;
}

.top-full {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
}

.middle-full {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
    -ms-transform: translateX(0%) translateY(-50%) rotate(0deg);
    -webkit-transform: translateX(0%) translateY(-50%) rotate(0deg);
    transform: translateX(0%) translateY(-50%) rotate(0deg);
}

.bottom-full {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
}



/* ================== Badge color CSS ========================*/
.badge.red {
    background: #DB4B04;
}

.badge.orange {
    background: #fa7901;
}

.badge.pink {
    background: #ee2b8b;
}

.badge.blue {
    background: #00adee;
}

.badge.green {
    background:  #46C062;
}

