
.onboardForm{
    margin-top: 70px;
    margin-bottom: 70px;
    width: 100%;
}

.loginImg{
    width: 80%!important;
    height: auto;
    max-height: 200px!important;
}

.site-btn{
    font-weight: 300;
    font-family: 'Baloo Da 2', cursive !important;
    color: white !important;
    background: #32ba04 !important;
    border-radius: 0px;
}

.site-btn:focus{
    border-color: #32ba04;
}


.site-btn:active{
    background: #32ba04 !important;
}
