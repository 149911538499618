/*...Reviewed By Rabbil Hasan...*/
.navbar{
    background: #46C062 !important;
    box-shadow: 0 .2rem .4rem rgba(0,0,0,.075) !important;
}
.nav-link{
    text-decoration: none!important;
    font-size: 18px;
    font-family: 'Baloo Da 2', cursive !important;
    font-weight: 500;
    transition: 0.3s;
    color: #ffffff!important;
}
.nav-link:hover{
    transition: 0.3s;
    color: #000000 !important;
}
.nav-item{
    text-decoration: none!important;
    font-size: 18px;
    font-family: 'Baloo Da 2', cursive !important;
    font-weight: 500;
    transition: 0.3s;
    color: #ffffff !important;
}
.nav-item:hover{
    transition: 0.3s;
    color: #000000 !important;
}
.nav-dark-green{
    background-color: #1F8751;
}
.nav-item-mobile{
    text-decoration: none!important;
    font-size: 18px;
    font-family: 'Baloo Da 2', cursive !important;
    font-weight: 500;
    transition: 0.3s;
    color: #1b1e21!important;
}
.nav-item-mobile:hover{
    transition: 0.3s;
    color: #46C062 !important;
}
.nav-logo{
    height: 70px;
    width: auto;
}
.form-search-control{
    font-size: 18px;
    border-radius: 4px 0  0  4px!important;
}
.form-search-control:focus{
    border-radius: 4px 0  0  4px!important;
}
.nav-item-top{
    text-decoration: none!important;
    font-size: 18px;
    font-family: 'Baloo Da 2', cursive !important;
    font-weight: 500;
    color: #000000!important;
    transition: 0.3s;
}
.nav-item-top:hover{
    transition: 0.3s;
    color: #46C062!important;
}
.nav-top-top{
    overflow-x: hidden;
    background-color: #eeeeee;
    padding:5px !important;
}
.nav-top-btn{
    font-family: 'Baloo Da 2', cursive !important;
    font-weight: 500;
    font-size: 13px;
}
.nav-top-btn:hover{
    color: #46C062 !important;
}



/*...Mobile...*/
.nav-mobile-top{
    margin-bottom: 58px !important;
}
.navbar-mobile{
    background: #ffffff !important;
    box-shadow: 0 .2rem .4rem rgba(0,0,0,.075) !important;
}
.navbar-mobile-top{
    padding: 10px;
    height: 100px;
    background:  #46C062!important;
}
.sideNavOpen {
    height: 100%;
    width: 240px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    font-size: 16px;
    transition: 0.1s;
    background-color: #ffffff;
    box-shadow: 0 .6rem 0 rgba(0,0,0,.075) !important;
}
.sideNavClose {
    height: 100%;
    width: 0px;
    position: fixed;
    top: 0;
    font-size: 20px;
    left: 0;
    z-index: 10;
    overflow-x: hidden;
    transition: 0.1s;
    background-color: #161616;
    box-shadow: 0 0 8px 0 rgba(57, 78, 234, 0.1);
}
.ContentOverlayOpen {
    display: block;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 7;
    right: 0;
    bottom: 0;
    cursor: pointer;
    background-color: rgba(0,0,0,0.5);
}
.ContentOverlayClose {
    display: none;
}
.nav-menu-mobile-item{
    color: #444;
    padding: 7px;
}








