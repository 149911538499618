@import url('https://fonts.googleapis.com/css2?family=Baloo+Da+2:wght@400;500;600;700&display=swap');
@import "btn.css";
@import "banner.css";
@import "card.css";
@import "footer.css";
@import "img.css";
@import "nav.css";

@import "section.css";
@import "typo.css";
@import "video.css";
@import "onboard.css";
@import "blog.css";
@import "map.css";
@import "notification.css";
@import "product.css";
@import "order.css";
@import "cart.css";
@import "login.css";
@import "other.css";
@import "review.css";
@import "input.css";
@import "Toast.css";
@import "loader.css";
@import "responsive.css";



body {
    background-color: #F7F7F7 !important;
    font-family: 'Baloo Da 2', cursive !important;
}
a{
    text-decoration: none !important;
}
a:hover{
    text-decoration: none !important;
}
.nodata-img{
    width: 100%;
    height: auto;
}
.noreview-div{
    margin-top: 50px ;
    margin-bottom: 50px;
}
.nodata-div{
    margin-top: 80px ;
    margin-bottom: 300px;
}
.nocart-div{
    margin-top: 60px ;
    margin-bottom: 60px;
}