.date{
    font-size: 14px;
    font-family: 'Baloo Da 2', cursive !important;
    font-weight: 300;
    color: #1b1e21;
}

.blogTitle{
    font-size: 18px;
    font-family: 'Baloo Da 2', cursive !important;
    font-weight: 300;
    color: #1b1e21;
}

.blogDes{
    font-size: 14px;
    font-family: 'Baloo Da 2', cursive !important;
    font-weight: 400;
    color: #434343;
}

.readText{
    font-size: 14px;
    font-family: 'Baloo Da 2', cursive !important;
    font-weight: 500;
    color: #0eac2b;
    text-decoration: none!important;
}

.readText:hover{
    color: #1b1e21;
    text-decoration: none!important;
}

.blogCard{
    border-radius: 0px;
    border-color: #f5f5f5;
    background: white;
    box-shadow: 0 0 2px 0 rgba(57, 78, 234, 0.1);
}

.blogCard:hover {
    box-shadow: 0 0 8px 0 rgba(57, 78, 234, 0.1);
}

/* Blog Details Css */

.blogImg{
    width: 100%;
    height: auto;
    max-height: 200px!important;
    max-width: 400px!important;
}

.blogDetailsImage{
    width: 100%;
    height: auto;
    max-height: 280px!important;
}

.blogDetailsCard {
    background-color: white;
    padding: 20px 30px 20px 30px;
    margin-top: 20px;
    border-radius: 0px;
    border-color: #fafafa !important;
}

.blogDetailsTitle{
    font-size: 24px;
    font-family: 'Baloo Da 2', cursive !important;
    font-weight: 400;
    color: #1b1e21;
}

.blogDetailsDes{
    font-size: 16px;
    font-family: 'Baloo Da 2', cursive !important;
    font-weight: 300;
    color: #434343;
}



/* Blog Details Comment Section */

.blogDetailsCommentCard {
    background-color: white;
    padding: 20px 25px 20px 25px;
    border-radius: 0px;
    border-color: #fafafa !important;
}

.commentsTitle {
    font-size: 18px;
    color: #4a4a4a;
    margin-bottom: 15px;
    font-family: 'Baloo Da 2', cursive !important;
}

.img-comment {
    width: 10px!important;
    height: 30px!important;
    float: left;
    margin-top: 25px!important;
}

.user-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.comment-content {
    margin-left: 50px;
}

.comment-content span {
    display: inline-block;
    width: 50%;
    margin-bottom: 15px;
}

.comment-name {
    font-size: 13px;
    font-family: 'Baloo Da 2', cursive !important;
}

.comment-content a {
    color: #383b43;
}

.comment-content span {
    display: inline-block;
    width: 49%;
    margin-bottom: 15px;
}

.comment-date {
    text-align: right;
    font-size: 11px;
    color: #b4b7c1;
    font-family: 'Baloo Da 2', cursive !important;
}

.comment-des {
    font-size: 14px;
    line-height: 18px;
    color: #393d43;
    display: block;
    background: #f8f8fa;
    border: 1px solid #edeff2;
    padding: 15px 20px 20px 20px;
    font-family: 'Baloo Da 2', cursive !important;
    font-weight: 300;
}



.comment-btn{
    color: #ffffff;
    font-family: 'Baloo Da 2', cursive !important;
    font-weight: 400;
    font-size: 18px;
    background-color: #0cb131;
    border-radius: 1px;
    box-shadow: none!important;
    border-color: #0cb131;
}

.comment-btn:hover{
    color: #ffffff;
    background-color: #000000;
    box-shadow: none!important;
    border-color: #000000;
}

