/* Reviewed By Rabbil Hasan */
.top-banner{
    min-height: 360px;
    padding: 20px;

    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.mango{
    background-color:#ffffff !important;
}
.honey{
    background-color:#ffffff !important;
}
.molasses{
    background-color:#ffffff !important;
}
.tree{
    background-color:#ffffff !important;
}
.top-banner-margin{
    margin-top: 70px;
}
.banner-item-img{
    margin: 0 auto;
    width: 100%;
    height: auto;
    max-height: 360px;
}
.banner-title{
    color: #46C062;
    font-size: 48px;
    font-weight: 700;
}
.banner-sub-title{
    color: #BB3302;
    font-size: 32px;
    font-weight: 500;
}
.slider-overflow{
    width: 100% !important;
    overflow-x: hidden !important
}
