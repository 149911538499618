.login-title{
    font-family: 'Baloo Da 2', cursive !important;
    font-size: 24px;
    font-weight: 400;
    color: #1b1e21;
}

.login-subtitle{
    font-family: 'Baloo Da 2', cursive !important;
    font-size: 15px;
    font-weight: 300;
    color: #606060;
}

