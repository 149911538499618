/*Completed By Rabbil Hasan*/
.notification-date{
    font-size: 14px;
    font-family: 'Baloo Da 2', cursive !important;
    font-weight: 700;
    color: #1b1e21;
}
.notification-title{
    font-family: 'Baloo Da 2', cursive !important;
    font-size: 16px;
    font-weight: 500;
}
.notification-des{
    font-family: 'Baloo Da 2', cursive !important;
    font-size: 14px;
    font-weight: 300;
    color: #494949;
}