.videoTopSection{
    margin-top: -15px!important;
    box-shadow: 0 0 1px 0 rgb(245, 245, 245);
}

.date{
    font-size: 14px;
    font-family: 'Baloo Da 2', cursive !important;
    font-weight: 300;
    color: #1b1e21;
}

.videoTitle{
    font-size: 17px;
    font-family: 'Baloo Da 2', cursive !important;
    font-weight: 300;
    color: #1b1e21;
    margin-top: 0px!important;
}

.videoDes{
    font-size: 14px;
    font-family: 'Baloo Da 2', cursive !important;
    font-weight: 400;
    color: #434343;
}

.watchText{
    font-size: 16px;
    font-family: 'Baloo Da 2', cursive !important;
    font-weight: 500;
    color: #be0505;
    text-decoration: none!important;
}

.watchText:hover{
    color: #1b1e21;
    text-decoration: none!important;
}

.videoCard{
    border-radius: 0px;
    border-color: #fdfdfd;
    background: #fafafa;
    box-shadow: 0 0 1px 0 rgba(57, 78, 234, 0.1);
}

.videoCard:hover {
    box-shadow: 0 0 8px 0 rgba(57, 78, 234, 0.1);
}


.playBtn{
    color: #ff0000;
    font-size: 38px;
    margin: 0px 0px 3px 0px!important;
    padding: 0px!important;
}

.playBtn:hover{
    color: #2d2d2d;
}


/* Video Details Comment Section */

.videoDetailsCard {
    background-color: white;
    padding: 10px 10px 10px 10px;
    margin-top: 20px;
    border-radius: 0px;
    border-color: #fafafa !important;
}

.videoDetailsTitle{
    font-size: 22px;
    font-family: 'Baloo Da 2', cursive !important;
    font-weight: 400;
    color: #1b1e21;
}

.videoDetailsDes{
    font-size: 16px;
    font-family: 'Baloo Da 2', cursive !important;
    font-weight: 300;
    color: #434343;
}


.videoImg{
    width: 100%;
    height: auto;
    max-height: 130px!important;
}

/* Video Details Comment Section */

.videoDetailsCommentCard {
    background-color: white;
    padding: 20px 20px 20px 20px;
    border-radius: 0px;
    border-color: #fafafa !important;
}

.commentsTitle {
    font-size: 18px;
    color: #4a4a4a;
    margin-bottom: 15px;
    font-family: 'Baloo Da 2', cursive !important;
}

.img-comment {
    width: 10px!important;
    height: 30px!important;
    float: left;
    margin-top: 25px!important;
}

.user-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.comment-content {
    margin-left: 50px;
}

.comment-content span {
    display: inline-block;
    width: 50%;
    margin-bottom: 15px;
}

.comment-name {
    font-size: 13px;
    font-family: 'Baloo Da 2', cursive !important;
}

.comment-content a {
    color: #383b43;
}

.comment-content span {
    display: inline-block;
    width: 49%;
    margin-bottom: 15px;
}

.comment-date {
    text-align: right;
    font-size: 11px;
    color: #b4b7c1;
    font-family: 'Baloo Da 2', cursive !important;
}

.comment-des {
    font-size: 14px;
    line-height: 18px;
    color: #393d43;
    display: block;
    background: #f8f8fa;
    border: 1px solid #edeff2;
    padding: 15px 20px 20px 20px;
    font-family: 'Baloo Da 2', cursive !important;
    font-weight: 300;
}

.comment-btn{
    color: #ffffff;
    font-family: 'Baloo Da 2', cursive !important;
    font-weight: 400;
    font-size: 18px;
    background-color: #0cb131;
    border-radius: 1px;
    box-shadow: none!important;
    border-color: #0cb131;
}

.comment-btn:hover{
    color: #ffffff;
    background-color: #000000;
    box-shadow: none!important;
    border-color: #000000;
}