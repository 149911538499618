/* Reviewed By Rabbil Hasan */
.GoogleMap{
    margin: 0!important;
    width: 100% !important;
    height: 400px!important;
    border: none;
    padding:0 !important
}



